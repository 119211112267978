export const APIMICK = "https://localhost:32770/api";
{
    /* Mick local host */
}
// export const APIRJB= "https://localhost:44375/api"; {/* Richard local host **/}
// export const API = process.env.REACT_APP_API_URL;
// export const APIKEY = process.env.REACT_APP_API_KEY;
// export const API = "https://staging-vesta.mysasure.com/api"; // TODO:
// export const API =    "https://dev-vesta.mysasure.com/api";
//  export const API = "https://localhost:44375/api";
// export const API = "http://localhost:5001/api"; /*{ local api url *}*/
// export const API = "http://192.168.0.21:5001/api"; /*{ local api url *}*/
// export const API = "https://dev-vesta.mysasure.com/api";

export const API = "https://vesta.mysasure.com/api"; {/* production api url **/ }
export const APIKEY = "eb671f43-0e9c-495d-8a3d-2439048a3cac";
